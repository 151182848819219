.menu__head {
    border-top: 1px solid #C4C4C4;
    border-bottom: 2px solid #C4C4C4;
    background-color: white;
  }
  
  .menu__head-list {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .menu__head-list__item {
    text-align: center;
    width: 300px;
  }
  
  .menu__head-list__link {
    text-decoration: none;
    font-style: 24px;
    line-height: 28px;
    color: #888888;
    display: block;
    padding: 14px 10px 18px;
    border-left: 1px solid #c4c4c4;
    border-right: 1px solid #c4c4c4;
    transition: all .3s;
  }
  
  .menu__link--active {
    color: #ffffff;
    background-color: #000000;
  }
  
  .menu__link--active,
  .menu__head-list__link:hover {
    color: #ffffff;
    background-color: #000000;
  }
  
  .menu__head-list__item:first-child {
    border-left: 1px solid #c4c4c4;
  }
  
  .menu__head-list__item:last-child {
    border-right: 1px solid #c4c4c4;
  }

  @media (max-width: 1230px) {
    .menu__head-list__item {
      width: 250px;
    }
  }

  @media (max-width: 1030px) {
    .menu__head-list__item {
      width: 200px;
    }
  }

  @media (max-width: 830px) {
    .menu__head-list__item {
      width: 150px;
    }
  }
  
  @media (max-width: 629px) {
    .menu__head {
      border-bottom: 1px solid #c4c4c4;
    }

    .menu__head-list {
      display: block;
    }
  
    .menu__head-list__item {
      width: 100%;
      border-top: 1px solid #c4c4c4;
    }
  
    .menu__head-list__link{
     padding: 10px 10px;
    }
  }  
  