.categories {
  background: linear-gradient(180deg, rgb(255, 255, 255) 
  50%, #eeeeee 100%);
}

.categories__items {
  display: flex;
  justify-content: space-around;
  padding-bottom: 50px;
  flex-wrap: wrap;
}


