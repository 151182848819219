.account__items {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.profile__btn {
  background-color: rgb(150, 150, 150);
  color: whitesmoke;
  font-size: 18px;
  line-height: 20px;
  padding: 13px 48px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 50px;
}

.profile__btn:hover {
  background-color: rgb(1, 150, 1);
  color: white;
}

textarea:-moz-placeholder {
  color: #000;
  font-size: 18px;
  line-height: 20px;
}

textarea::-webkit-input-placeholder {
  color: #000;
  font-size: 18px;
  line-height: 20px;
}