.services__pg{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap; 
}

.services__description {
  color: white;
  font-size: 20px;
  line-height: 22px;
  margin: 20px 0px;
  padding: 20px;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 10px;
}

.fz-16 {
  font-size: 16px;
  line-height: 18px;
}

.categories__pg-items {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.74);
  border-radius: 20px;
  margin: 10px;
}