.contacts__text {
  font-size: 26px;
  line-height: 28px;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 40px;
}

.contacts__items {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

