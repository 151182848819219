.services__pg{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
 
}

.services__page-img {
  width: 100%;
  height: 180px;
  margin-bottom: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.74);
  margin-top: 15px;
}

.services__page-img3 {
  background-image: url(../../img/services-pg3.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.services__description-title {
  width: 100%;
  color: white;
  font-size: 22px;
  line-height: 24px;
  padding: 10px;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 10px;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 10px;
}

.services__description-text {
  width: 100%;
  color: black;
  font-size: 16px;
  line-height: 18px;
  padding: 10px;
  border-radius: 10px;
  margin-left: 30px;
}

.services__description-list {
  margin-bottom: 20px;
  margin-left: 50px;
  margin-top: 10px;
  text-align: left;
}

.services__description-list li {
  padding: 5px;
}

.fz-16 {
  font-size: 16px;
  line-height: 18px;
}
