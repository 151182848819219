.sidebar__item-services {
  background-color: black;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.4);
  text-decoration: none;
  font-size: 16px;
  line-height: 18px;
  color: white;
  border: 2px solid gold;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 15px;
}

.sidebar__item-services:hover {
  background-color: #24bba7;
  color: white;
  /* border: 2px solid rgb(88, 218, 37); */
}

.sidebar__services-active {
  background-color: #24bba7;
  color: white;
}