.section__title {
  background-color: #000;
  color: white;
  font-size: 48px;
  line-height: 50px;
  font-family: 'PT Serif', serif;
  padding: 18px 15px;
  text-align: center;
}

.border-top {
  border-top: 1px solid white;
}

@media (max-width: 705px) {
  .section__title {
    font-size: 38px;
    line-height: 40px;
  }
}

@media (max-width: 509px) {
  .section__title {
    font-size: 30px;
    line-height: 32px;
  }
}