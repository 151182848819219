.header {
  background: #000;
  padding-top: 5px;
}
.header__items {
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: white;
  min-height: 70px;
}

.header__left {
  display: flex;
  font-family: 'Cormorant', serif;
  font-size: 30px;
  line-height: 34px;
  cursor: pointer;
  color: white;
  text-decoration: none;
}

.header__left:hover {
  color: #d49e4b;
  transition: 0.9s;
}

.header__right {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.contact {
  display: flex;
  align-items: center;
  margin-right: 30px;
  margin-left: 10px;
}

.btn {
  text-decoration: none;
  border: 2px solid;
  color: #cc9748;
  padding: 5px 10px;
  cursor: pointer;
  margin-left: 10px;
}

@media (max-width: 1006px) {
  .header__right {
    margin: 10px;
  }
  .btn {
    border: 1px solid;
    padding: 5px 5px;
    margin-left: 5px;
  }
}

@media (max-width: 730px) {
  .header__left {
    font-size: 24px;
    line-height: 26px;
  }
}

@media (max-width: 705px) {
  .contact p {
    font-size: 14px;
  }

  .contact {
    margin-right: 15px;
  }

}

@media (max-width: 569px) {
  .contact {
    display: none;
  }
}