.content {
  background-image: url(../../img/bg-5.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.slider {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 600px;
  background: rgba(0, 0, 0, 0.6);
}

.slider-text {
  font-size: 38px;
  line-height: 40px;
  color: #ebad50;
  padding: 10px;
  text-align: center;
}

@media (max-width: 1006px) {
  .slider {
    min-height: 400px;
  }
}

@media (max-width: 665px) {
  .slider {
    min-height: 300px;
  }
  .slider-text {
    font-size: 30px;
    line-height: 32px;
  }
}

@media (max-width: 509px) {
  .slider {
    min-height: 270px;
  }
  .slider-text {
    font-size: 26px;
    line-height: 30px;
  }
}