.map {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
}
.wrap__map {
  background-color: black;
  height: 450px;
  width: 100%;
}

.wrap__map ymaps {
  opacity: 0.9;
}

@media (max-width: 830px) {
  .wrap__map {
    height: 300px;
  }
}

@media (max-width:450px) {
  .wrap__map {
    height: 250px;
  }
}