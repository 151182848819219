.sidebar {
  display: flex;
}

.sidebar__items {
  margin: 15px 0;
  padding: 0;
  width: 210px;
  background-color: white;
  height: max-content;
  overflow: auto;
  display: flex;
  flex-direction: column;

}