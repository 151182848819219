.profile__table {
  border: 2px solid black;
}

.account__items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 30px;
}

.profile__table-items th {
  background: rgba(0, 0, 0, 0.15);
}

.user__table-item {
  border: 1px solid grey;
  padding: 10px;
  cursor: pointer;
  vertical-align: top;
  min-width: 200px;
}

.request__table-item {
  text-align: center;
  border: 1px solid grey;
  padding: 5px;
  cursor: pointer;
}

.request-input {
  max-width: 180px;
  text-align: center;
}

.profile__btn {
  background-color: rgb(150, 150, 150);
  color: whitesmoke;
  font-size: 18px;
  line-height: 20px;
  padding: 8px 25px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

.profile__btn:hover {
  background-color: rgb(1, 150, 1);
  color: white;
}

.profile__btn-log {
  background-color: rgb(150, 150, 150);
  color: whitesmoke;
  font-size: 18px;
  line-height: 20px;
  padding: 8px 25px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 35px;
}

.profile__btn-log:hover {
  background-color: rgb(1, 150, 1);
  color: white;
}