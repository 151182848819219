.modal__password {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.45);
  display: flex;
}

.modal__request-dialog {
  max-width: 780px;
  width: 30%;
  border-radius: 5px;
  margin: auto;
  padding: 25px 35px;
  border: 2px solid #fdfdfd;
  background: black;
}

.modal__request-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.modal__title-sent {
  margin: 20px;
  color: whitesmoke;
  font-size: 20px;
 
 }

.close {
 font-size: 46px;
 border: none;
 background-color: transparent;
  cursor: pointer;
  color: white;
  position: relative;
  bottom: 30px;
  left: 15px;
} 
