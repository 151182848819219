.account-panel {
  padding-bottom: 100px;
  width: 1010px;
  margin-left: auto;
}

.account-panel__items {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 30px;
}