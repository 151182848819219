  
  .advanteges {
  background-image: url(../../img/bg-advant.png);
  background-repeat: repeat;
  background-position: center;
  }
  
  .advanteges__items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    padding: 20px 0;
  }
  .border-top {
    border-top: 1px solid white;
  }
  
  .border-bottom {
    border-bottom: 1px solid white;
  }

  @media (max-width: 830px) {
    .advanteges__items {
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      padding: 0px 0;
    }
  }