
.park-text {
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 40px;
}

.park__items {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin-bottom: 40px;
}

