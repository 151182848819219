.download {
  display: flex;
  flex-direction: column;
}

.download__btn {
  background-color: rgb(150, 150, 150);
  color: whitesmoke;
  font-size: 18px;
  line-height: 20px;
  padding: 13px 48px;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;
}

.download__btn:hover {
  background-color: rgb(1, 150, 1);
  color: white;
}
