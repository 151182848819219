
.sidebar__item {
  text-decoration: none;
  font-size: 14px;
  line-height: 16px;
  color: black;
  border: 1px solid black;
  padding: 15px;
  margin: 2px;
}

.sidebar__item:hover {
background-color: rgba(73, 73, 73, 0.7);
color: white;
}

.sidebar__home-item {
  text-decoration: none;
  font-size: 14px;
  line-height: 16px;
  color: black;
  border: 1px solid black;
  padding: 15px;
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sidebar__home-item:hover {
  background-color: rgba(73, 73, 73, 0.7);
  color: white; ;
}
