.categories__pg-item__title {
  text-align: center;
  margin: 5px 20px;
  font-size: 20px;
  line-height: 22px;
  color: white;
  padding: 10px;
  background: rgba(0, 0, 0, 0.9);
  border-radius: 10px;
}

.categories__pg-item {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 1010px;
  margin-top: 20px;
}

.categories__pg-item__img {
  margin: 20px 10px;
}
.categories__pg-item__icon {
  max-width: 300px;
}

.categoriea__pg-specifications {
  display: flex;
  align-items: center;
  margin: 10px;
}
.categoriea__pg-specifications-img {
  max-width: 40px;
  margin: 5px 20px;
}

.categoriea__pg-specifications-text {
  font-size: 16px;
}
.categories__pg-items {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.74);
  border-radius: 20px;
  margin: 10px;
}