.contacts-title {
  width: 100%;
  color: white;
  font-size: 22px;
  line-height: 24px;
  padding: 20px;
  background: rgba(0, 0, 0);
  border-radius: 10px;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 40px;
}

.contacts__items {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.bg__contacts {
  padding: 30px 5px;
}