.contacts__item {
  display: flex;
  align-items: center;
  margin: 5px;
}

.contacts__img-items {
  margin-right: 30px;
}

.contacts__info {
  width: 450px;
  padding: 10px;
}

.contacts__info-title {
  margin-bottom: 5px;
  font-size: 18px;
  line-height: 24px;
  align-items: start;
}

.contacts__info-text {
  font-size: 16px;
  line-height: 20px;
  align-items: start;
}

@media (max-width:600px) {
  .contacts__info {
    max-width: 300px;
    padding: 10px;
  }
  
  .contacts__info-title {
    margin-bottom: 5px;
    font-size: 18px;
    line-height: 20px;
    align-items: start;
  }
  
  .contacts__info-text {
    font-size: 16px;
    line-height: 18px;
    align-items: start;
  }
}

@media (max-width:450px) {
  .contacts__info {
    max-width: 250px;
    padding: 10px;
  }
  
  .contacts__info-title {
    margin-bottom: 5px;
    font-size: 18px;
    line-height: 20px;
    align-items: start;
  }
  
  .contacts__img-items {
    margin-right: 10px;
  }

  .contacts__info-text {
    font-size: 16px;
    line-height: 18px;
    align-items: start;
  }
}

@media (max-width:350px) {
 
  .contacts__img-items {
    display: none;
  }
}