.services__item-text {
  font-size: 12px;
  line-height: 14px;
  padding: 10px 10px 0px;
  color: rgb(201, 201, 201);
  margin-top: 150px;
  background: rgba(0, 0, 0, 0.5); 
  
}

.services__item-title {
  font-size: 18px;
  line-height: 21px;
  background: rgba(0, 0, 0, 0.5); 
  padding: 11px 10px 24px 10px;
}